<template>
  <div>
    <span class="viewH">Настройки</span>
    <hr>
    <div class="settings">
      <div class="pass">
        <span class="viewH">Изменение пароля учетной записи:</span>
        <div class="old-pass">
          <v-text-field
            class="pass-input"
            :label="passMess"
            outlined
            dense
            v-model="pass.oldPass"
            type="password"
          ></v-text-field>
        </div>
        <div class="new-pass">
          <v-text-field
            class="pass-input"
            label="Введите новый пароль"
            outlined
            dense
            :disabled="!pass.oldPass"
            v-model="pass.newPass"
            type="password"
          ></v-text-field>
          <v-text-field
            class="pass-input"
            label="Введите пароль еще раз"
            outlined
            dense
            :disabled="!pass.oldPass"
            v-model="pass.repeatNewPass"
            type="password"
          ></v-text-field>
          <div class="pass-btn">
            <v-btn small color="red" @click="cancel">Отмена</v-btn>
            <v-btn 
              small 
              color="green" 
              @click="_updPass" 
              :disabled="pass.newPass && pass.repeatNewPass && pass.newPass == pass.repeatNewPass ? false : true"
            >Изменить</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function
  },
  data: () => ({
    passMess: 'Введите текущий пароль',
    pass: {}
  }),
  methods: {
    ...mapActions(['updatePass']),
    async _updPass() {
      this.loadingWindow(true)
      this.pass.id = this.$store.getters.getUserID
      let res = await this.updatePass(this.pass)
      this.loadingWindow(false)
      this.cancel()
      if(res.updPass == false) this.messageOn('Неверный текущий пароль', 'red')
      if(res.updPass == true) this.messageOn('Пароль изменен', 'green')
    },
    cancel() {
      this.pass = {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .settings {
    margin-top: 25px;
    .pass {
      .old-pass {
        margin-top: 15px;
      }
      .pass-input {
        max-width: 300px;
      }
      .pass-btn {
        button {
          &:nth-child(2) {
            margin: 0 5px;
          }
        }
      }
    }
  }
</style>
